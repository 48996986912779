.alert {
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  color: black;
}

.alert-success {
  background: #dcfce7;
  border: 1px solid #15803d;
}

.alert-info {
  background: #dbeafe;
  border: 1px solid #1d4ed8;
}

.alert-warning {
  background: #fff7ed;
  border: 1px solid #9a3412;
}

.alert-error {
  background: #fee2e2;
  border: 1px solid #b91c1c;
}
