#navbar {
  height: 80px;
  background-color: $primaryColor;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4em;

  #navbar-logo {
    width: 159px;
    height: 43px;
  }

  #navbar-items {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    color: $lightTextColor;
    font-weight: 500;
    font-size: 16px;

    li {
      transition: all 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        color: $accentColor;
      }
    }
  }
}

#signout-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
