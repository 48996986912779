#model-evaluation-section {
  position: relative;
  background-color: $background;
  height: 150vh;

  #expand-evaluation-button {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    background: none;
    transition: all 0.3s ease-in-out;
    z-index: 1;

    svg {
      color: $primaryTextColor;
    }

    &:hover {
      svg {
        color: $primaryDark;
      }
    }
  }
}

#metrics-histogram-container,
#metrics-line-container {
  width: 100%;
  height: 100%;
  max-height: 100%;

  canvas {
    max-height: 100% !important;
  }

  #metrics-checkboxes {
    opacity: 0;
    transition: all 0.3s ease-in;
  }

  &:hover {
    #metrics-checkboxes {
      opacity: 1;
    }
  }
}
