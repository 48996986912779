#breadcrumbs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 1rem;
  max-width: 1280px;

  span {
    font-weight: 300;
    font-size: 16px;
    color: $AMD5;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    padding-top: 0.1rem;

    &:hover {
      color: $primaryDark;
    }
  }

  #current {
    font-weight: 700;
    font-size: 24px;
    color: $primaryDark;
    margin: 0;
  }

  svg {
    margin: 0.2rem 0.5rem 0 0.5rem;
    color: $greyTextColor;
  }
}
