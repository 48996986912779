#model-page,
#session,
.model-page-content {
  height: 100%;
  transition: 0.3s all ease;
  position: relative;
  min-height: 100vh;

  .mosaic-panel-section {
    position: absolute;
    inset: 0;
    height: 100%;
  }

  .active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
    z-index: 1;
  }

  .passive {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: -1;
  }
}

.project-tab {
  width: 100%;
  height: 100%;
}
