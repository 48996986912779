#whatif-section {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .form-item-label {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 14px;
    font-weight: bold;
    font-family: $font;
  }

  #whatif-top {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 30px;

    #whatif-left {
      width: 340px;
    }

    #whatif-right {
      flex: 1;
    }
  }
}
