.mentions--singleLine .mentions__control {
  display: inline-block;
  padding-left: 10px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: none;
}
.mentions--singleLine .mentions__input {
  border: none;
  font-style: italic;
  padding-left: 10px;
  padding-right: 1rem;
}

.mentions--multiLine .mentions__control {
  font-family: monospace;
  font-size: 12pt;
}
.mentions--multiLine .mentions__highlighter {
  border: none;
  padding: 9px;
  min-height: 63px;
}
.mentions--multiLine .mentions__input {
  border: none;
  padding: 9px;
  outline: 0;
  font-style: italic;
}
.mentions__suggestions {
  background-color: red;
  border: 1px solid rgba(0, 0, 0, 0.15);
  max-height: 10rem;
  overflow: auto;
}
.mentions__suggestions__list {
  font-size: 10pt;
}
.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.mentions__suggestions__item--focused {
  background-color: #002169;
  color: #fff;
}
.mentions__mention {
  position: relative;
  z-index: 1;
  color: #002169;
  font-style: italic;
  text-shadow:
    1px 1px 1px white,
    1px -1px 1px white,
    -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: none;
  pointer-events: none;
}
