.dropzone {
  color: $greyTextColor;
  background-color: $backgroundDark;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed $greyTextColor;
  border-radius: 5px;
  cursor: pointer;

  &.with-file {
    border-color: $primaryDark;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
  }
}
