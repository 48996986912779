#project-table {
  .table-body {
    overflow-y: auto;
    height: 65vh;
  }
}

#projects-top {
  display: flex;
  justify-content: space-between;

  #project-search {
    filter: none;
    border: 1px solid $primaryColor;
    width: 330px;
    font-weight: normal;
    font-size: 14px;
  }
}

#project {
  width: 100%;
}
