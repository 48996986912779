.mosaic-root {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mosaic-tile {
  margin: 0;
}

#add-expression-modal-evaluation .mosaic-window,
#session .mosaic-window {
  border-radius: 0;

  .mosaic-window-body {
    border-radius: 0;
    background-color: white;
  }

  .mosaic-window-toolbar {
    height: 20px;
    border-radius: 0;
  }

  .mosaic-window-controls {
    button {
      height: auto;
      filter: none;
      border-radius: none;
      background: none;
      transition: all 0.3s ease-in-out;
    }

    .expand-button {
      &::before {
        content: url(../svgs/expand.svg);
        display: block;
      }

      &:hover {
        &::before {
          content: url(../svgs/expand_hover.svg);
          display: block;
        }
      }
    }

    .close-button {
      &::before {
        content: url(../svgs/close.svg);
        display: block;
      }

      &:hover {
        &::before {
          content: url(../svgs/close_hover.svg);
          display: block;
        }
      }
    }

    .split-button {
      &::before {
        content: url(../svgs/split.svg);
        display: block;
      }

      &:hover {
        &::before {
          content: url(../svgs/split_hover.svg);
          display: block;
        }
      }
    }

    .swap-button {
      &::before {
        content: url(../svgs/replace.svg);
        display: block;
      }

      &:hover {
        &::before {
          content: url(../svgs/replace_hover.svg);
          display: block;
        }
      }
    }
  }

  .mosaic-window-title {
    font-size: 12px;
    font-weight: 400;
    color: $primaryDark;
  }
}

.mosaic-split {
  &:hover {
    .mosaic-split-line {
      box-shadow: 0 0 0 1px $primaryDark;
    }
  }

  .mosaic-split-line {
    box-shadow: 0 0 0 1px $AMD4;
  }
}
