#comparison-section {
  display: flex;
  flex-direction: row;

  #comparison-right {
    background-color: $background;
    width: 100%;
    min-height: 120vh;
    position: relative;

    .close-button {
      display: none;
    }
  }

  #expression-filters {
    width: 320px;
    height: 100%;
    padding: 2rem 1rem 1rem 1rem;
    transition: all 0.5s ease-in;
    overflow: auto;

    &.minimized {
      width: 0;
      min-width: 0;
      padding: 2rem 0;
    }

    h5 {
      font-size: 16px;
      color: $primaryTextColor;
      margin-bottom: 1rem;
      margin-top: 0;
    }

    .slider-filter-container {
      margin-bottom: 2.5rem;

      label {
        font-weight: 600;
        font-size: 12px;
        color: #002169;
        text-transform: uppercase;
      }
    }

    .rc-slider {
      margin-top: 0.5rem;
      margin-left: 0.5rem;
    }

    .rc-slider-track {
      height: 1px;
      background-color: #009ee2;
      margin-top: 4px;
    }

    .rc-slider-rail {
      height: 1px;
      margin-top: 4px;
    }

    .rc-slider-handle {
      width: 19px;
      height: 19px;
      border: solid 1px #009ee2;
      background-color: white;
      opacity: 1;
      z-index: 5;
    }

    .rc-slider-dot {
      bottom: -5px;
    }

    .rc-slider-mark-text {
      color: $accentColor;
      font-weight: 300;
      font-size: 14px;
      webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
    }
  }

  #comparison-table {
    height: 100%;

    .table-body {
      overflow-y: auto;
      scroll-behavior: smooth;
      height: calc(100% - 50px);
      min-width: 1000px;
    }

    .table-entry {
      background-color: white;
      justify-content: flex-start;
      transition: all 0.3s ease-in-out;

      &:nth-child(2n) {
        background-color: $backgroundDark;
      }

      &.highlighted {
        color: $primaryDark;
        background-color: $accentLight;
      }
    }

    .generation {
      padding-left: 1rem;
    }

    .metric {
      width: 100px;
      padding: 0 0.5rem;
      overflow-x: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .expression {
      min-width: 180px;
    }

    span {
      margin-right: 1rem;
    }

    .order-caret {
      color: $AMD4;
      transition: all 0.3s ease-in;
      min-width: 15px;

      &:hover {
        color: $primaryDark;
      }

      &.selected {
        color: $primaryDark;

        &:hover {
          color: $primaryLight;
        }
      }
    }

    .table-top {
      width: 100%;

      & > span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        & > span {
          margin-right: 5px;
        }
      }
    }
  }

  #comparison-toggler {
    text-align: right;
    margin-top: 2.5rem;

    button {
      width: 100px;
      border-radius: 0;
      background-color: $AMD4;
      color: $greyTextColor;
      font-size: 14px;
      height: 30px;
      outline: none;

      &:hover {
        background-color: $primaryDark;
      }

      &.selected {
        background-color: $primaryDark;
        color: white;

        &:hover {
          background-color: $primaryLight;
        }
      }
    }
  }

  #scatter-container {
    position: relative;
    width: inherit;
    height: 100%;
    overflow: auto;
    padding: 0 .75rem 2rem 0;

    canvas {
      position: relative;
      top: 40px;
    }

    #scatter-axis-picker {
      position: absolute;
      top: 5px;
      right: 10px;
      display: flex;
      flex-wrap: wrap;
    }

    .dropdown-container {
      margin-left: 0.3rem;
      div[class*="control"] {
        margin: 0;
        border-radius: 0;
        box-shadow: none;
        filter: none;
        border: 1px solid $primaryDark;
      }

      input {
        height: 20px;
      }

      div[class*="ValueContainer"],
      div[class*="singleValue"],
      div[class*="option"] {
        margin: 0;
        font-size: 12px;
      }
    }
  }

  #bar-chart-container {
    position: relative;
    overflow-y: auto;

    #bar-chart {
      width: 100% !important;
      height: 100% !important;
    }

    #metrics-checkboxes {
      opacity: 0;
      transition: all 0.3s ease-in;
    }

    &:hover {
      #metrics-checkboxes {
        opacity: 1;
      }
    }
  }
}

#metrics-checkboxes {
  position: absolute;
  top: 1rem;
  right: 0.4rem;
  background-color: white;
  border: 1px solid $primaryDark;
  font-size: 12px;
  width: 90px;

  .checkbox-container {
    padding: 0.1rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .checkbox {
    min-width: 10px;
    min-height: 10px;
    border: 1px solid $primaryDark;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: $accentLight;
    }

    &.selected {
      background-color: $accentDark;
    }
  }
}

.bookmark {
  display: flex;
  // width: 20px;

  svg {
    transition: all 0.3s ease-in;
    color: $AMD3;

    &:hover {
      color: $primaryDark;
    }
  }

  &.selected {
    svg {
      color: $primaryDark;

      &:hover {
        color: $primaryLight;
      }
    }
  }
}
