*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.3s;
  color: $primaryTextColor;
  font-family: $font;
  background-color: $background;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none !important;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul {
  padding-left: 0;
}

li {
  list-style: none;
}

nav > * {
  cursor: pointer;
}

.page-content {
  max-width: 1280px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 2rem 1rem 2rem 1rem;
}

.full-width-page {
  width: 100%;
  max-width: 1440px;
  // padding: 2rem 0rem;
}

#root {
  width: 100%;
  height: 100%;

  & > div {
    height: 100%;
    width: 100%;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $AMD3;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $loadingColor;
}
