#login-page {
  background: radial-gradient(
    circle,
    rgba(1, 1, 255, 1) 0%,
    rgba(0, 0, 166, 1) 35%,
    rgba(0, 0, 78, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  height: auto !important;
  justify-content: space-between;
}

#jumbotron {
  margin: 2.5rem auto 1rem auto;

  img {
    display: block;
    margin: 0 auto;
    height: 4rem;
  }

  h4 {
    font-size: 24px;
    color: $lightTextColor;
    font-weight: 400;
    margin-top: 1rem;
    text-align: center;
  }
}

#register-box,
#login-box {
  margin: 0 auto;
  background: $white;
  border-radius: 0.5rem;
  padding: 1.5rem 2rem 0 2rem;
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
  width: 100%;
  max-width: 25rem;

  h5 {
    font-size: 20px;
    margin: 0;
    color: $primaryColor;
    text-align: center;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .input-container {
    label {
      font-weight: 600;
      font-size: 16px;
    }

    input {
      font-weight: 400;
      font-size: 14px;
      width: 100%;
      margin-top: 8px;
      margin-bottom: 22px;

      &::placeholder {
        color: $greyTextColor;
      }
    }

    #login-button {
      width: 100%;
      margin-top: 22px;
    }
  }
}

#register-box {
  max-width: 36rem;
}

#partners-container {
  justify-self: end;
  background: rgba($color: $white, $alpha: 0.8);
  padding: 0 1rem;
  border-radius: 1rem;
  margin: 3rem 1rem 1rem 1rem;
}

#partners-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  li {
    padding: 0.5rem 1.5rem;
  }
}

#no-account {
  text-align: right;
  font-weight: 300;
  font-size: 14px;
  color: $primaryColor;
  margin-top: 20px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;

  &:hover {
    color: $primaryColor;
  }
}
