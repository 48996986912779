.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 90%;
  max-height: 100%;
  max-width: 625px;
  padding: 23px 43px;

  background: $background;
}

.modal-title {
  font-weight: 400;
  font-size: 32px;
  margin: 0;
  margin-bottom: 15px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  box-shadow: none;
  transition: 0.3s all ease-in-out;

  &:hover {
    color: $primaryDark;
  }
}

.modal-form {
  label {
    font-weight: 600;
    font-size: 16px;
  }

  input {
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 22px;
  }
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  button {
    margin-left: 1rem;
    width: 160px;
    transition: 0.3s all ease-in-out;
    color: white;
  }
}
