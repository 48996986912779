#tree-viz-container {
  position: relative;
  touch-action: none;
  height: 100%;
  overflow: auto;

  #tree-container {
    width: fit-content;
    padding: 1rem 2rem;
  }

  #tree-visualizer {
    background-color: white;
    height: 100%;
    width: 100%;

    #left-tree-arrow,
    #right-tree-arrow {
      transition: all 0.3s ease-out;
      opacity: 0;
      position: absolute;
      top: 50%;
      background: none;
      filter: none;
      color: $AMD3;

      &:hover {
        color: $primaryDark;
      }
    }

    #left-tree-arrow {
      left: 10px;
      transform: translate(0, -50%);
    }

    #right-tree-arrow {
      right: 10px;
      transform: translate(0, -50%);
    }

    &:hover {
      #left-tree-arrow,
      #right-tree-arrow {
        opacity: 1;
      }
    }

    #tree-number {
      position: absolute;
      bottom: 16px;
      right: 16px;
      color: $AMD3;
    }

    #tree-identifier {
      position: absolute;
      top: 48px;
      right: 16px;
      font-weight: 500;
      font-size: 14px;
      color: $primaryDark;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  #tree-zoomer {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;

    button {
      height: 30px;
      width: 30px;
      background-color: $AMD3;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      filter: none;

      &:hover {
        background-color: $primaryDark;
      }
    }

    #zoom-in {
      border-radius: 3px 0 0 3px;
    }

    #zoom-out {
      border-radius: 0 3px 3px 0;
    }
  }
}

.tree-level {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100px;
  gap: 1rem;
}

.node {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .node-content {
    background-color: white;
    border: 2px solid $primaryColor;
    font: $button;
    border-radius: 5px;
    min-width: 40px;
    padding: 0.25rem 0.5rem;
    // height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .combiner-node {
    background-color: $primaryLight;
    color: $white;
  }
}
