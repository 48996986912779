#overview-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

#overview-left {
  display: flex;
  flex-direction: column;
  min-width: 340px;
  margin-right: 2rem;
}

#overview-right {
  width: 100%;
  height: 65vh;

  .table-body {
    overflow-y: auto;
    height: 65vh;
  }
}
