@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");

body {
  --primary-color: #0101ff;
  --danger-color: #e63312;
  --danger-dark-color: #ad1b00;
  --gray-border: #b9c3cb;
  --drop-shadow: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
}

// Main Colors

$primaryColor: #0101ff;
$primaryLight: #7e7eff;
$primaryDark: #002169;

$accentColor: #2ee09a;
$accentLight: #dcf7ed;
$accentDark: #00e088;

// Functional Colors

$background: #f9fafb;
$backgroundDark: #f5f5f5;
$primaryTextColor: #555555;
$lightTextColor: #f9fafb;
$greyTextColor: #c4c4c4;

$dangerColor: #e63312;
$dangerLight: #ff684d;
$dangerDark: #ad1b00;

$loadingColor: #aed2e7;
$white: #ffffff;

// Grey Colors

$AMD1: #f5f5f5;
$AMD2: #d0d8dd;
$AMD3: #b9c3cb;
$AMD4: #9facbd;
$AMD5: #8a99a8;
$AMD6: #5c6e83;

// Typography

$font: "Titillium Web", sans-serif;

$h1: 300 96px $font;
$h2: 300 60px $font;
$h3: 400 48px $font;
$h4: 400 34px $font;
$h5: 400 26px $font;
$h6: 400 16px $font;
$subtitle1: 400 22px $font;
$subtitle2: 500 18px $font;
$body1: 400 18px $font;
$body2: 400 16px $font;
$body3: 500 16px $font;
$body4: 600 12px $font;
$body5: 400 14px $font;
$button: 600 16px $font;
$input: 400 18px $font;
$caption: 400 12px $font;
$overline: 500 12px $font;
$error: 600 12px $font;
$placeholder: 500 14px $font;
$modalMessage: 400 24px $font;

// Shadows

$buttonShadow: drop-shadow(3px 3px 10px rgba(0, 0, 0, 0.25));
$inputShadow: drop-shadow(3px 3px 10px rgba(0, 0, 0, 0.25));
