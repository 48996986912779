#expression-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  font-family: monospace;
  font-size: 16px;

  #bookmark {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    z-index: 2;
    display: flex;

    svg {
      transition: all 0.3s ease-in;
      color: $AMD3;

      &:hover {
        color: $primaryDark;
      }

      &.selected {
        color: $primaryDark;
      }
    }
  }
}

#tree-expression-form {
  flex-grow: 2;
  position: relative;
  &.with-error {
    ::selection {
      background-color: $dangerLight;
      color: white;
    }
  }

  .tree-expression__input {
    font-size: 16px;
    line-height: 1.5;
    color: $primaryDark;
    font-family: monospace;
    white-space: pre-wrap;
    tab-size: 2;
  }
}

#pretty-expression-container {
  min-height: 50px;
  width: 100%;
  border-top: 1px solid $AMD4;
  filter: drop-shadow(0px -5px 10px rgba(0, 0, 0, 0.25));
  background-color: $background;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0.5rem;
  text-align: center;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $AMD3;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $loadingColor;
  }

  span {
    padding-left: 2rem;
    padding-right: 1rem;
  }

  #copy-indicator {
    opacity: 0;
    position: absolute;
    bottom: 5px;
    left: 4px;
    transition: all 0.3s ease-in-out;
    background-color: $primaryTextColor;
    color: white;
    font-size: 12px;
    padding: 0 0.5rem;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    p {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    #copied-icon {
      color: $accentColor;
      margin-right: 0.25rem;
    }
  }

  &:hover {
    #copy-indicator {
      opacity: 0.7;
    }
  }
}

// #tree-expression,
// #highlighting {
//   color: $primaryDark;
//   background-color: transparent;
// }

// #tree-expression,
// #highlighting,
// #highlighting * {
//   font-weight: normal;
//   font-size: 16px;
//   font-family: monospace;
//   line-height: 1.5;
//   white-space: pre-wrap;
//   tab-size: 2;
// }

// #tree-expression {
//   color: $primaryTextColor;
//   background: transparent;
//   caret-color: red;
//   z-index: 1;
//   padding: 2px;
// }

#highlighting {
  z-index: 0;
  margin: 0;
  padding: 2px;
  // width: 100%;
}

#update-expression-button {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  z-index: 1;
}

#expression-error {
  color: $dangerColor;
  font: $error;
  padding-left: 1em;
  height: auto;
  width: fit-content;
  padding: 0.5em 1em;
  background: white;
  filter: $buttonShadow;
  border-radius: 5px;
  max-width: 250px;
}
