
#feature-importance-section,
#counterfactual-section {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .form-item-label {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 14px;
    font-weight: bold;
    font-family: $font;
  }

  .section-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 1.5rem;
    font-family: $font;
  }

  .underline {
    width: 120px;
    height: 1px;
    background: #000000;
    border-radius: 8px;
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
  }

  #explanation-card {
    position: relative;
    background: white;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.05);
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      border-color: $primaryColor;
    }

    .card-title-section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      #title {
        font-size: 14px;
        font-weight: bold;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: $font;
      }
    }

    .info-container {
      display: flex;
      align-items: center;
      padding: 0.5rem 0;

      p {
        margin: 0;
        font-family: $font;
      }

      .info-item-title {
        width: 100px;
        flex-shrink: 0;
      }
    }
  }
}
