.title-tabs-container {
  background-color: $backgroundDark;
  border-bottom: 1px solid $AMD2;
}

.tabs-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 1rem;
  // max-width: 1280px;

  li {
    font-size: 16px;
    font-weight: 400;
    color: $primaryTextColor;
    padding: 5px 20px;
    transition: all 0.3s ease-in-out;
    margin-right: 8px;
    border-bottom: 2px solid rgba($color: #000000, $alpha: 0);
    transition:
      visibility 0.3s linear,
      opacity 0.3s linear;

    &:hover {
      cursor: pointer;
      border-color: $AMD5;
    }

    &.selected {
      font-weight: 600;
      border-color: $accentColor;
    }

    &.unavailable {
      visibility: hidden;
      opacity: 0;
    }

    &.available {
      visibility: visible;
      opacity: 1;
    }
  }
}
