#root {
  position: relative;
  #page-loader {
    display: inline-block;
    border: 6px solid $greyTextColor;
    border-top: 6px solid $primaryColor;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: calc(50vh - 25px);
    left: calc(50vw - 25px);
  }

  .table-loader {
    display: inline-block;
    border: 3px solid $greyTextColor;
    border-top: 3px solid $loadingColor;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;
    position: absolute;
    left: 50%;
    top: 10rem;
  }

  .tile-loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .tile-loader {
    display: inline-block;
    border: 3px solid $greyTextColor;
    border-top: 3px solid $loadingColor;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;
  }

  .tile-no-solutions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
