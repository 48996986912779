#datasets-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  #datasets-left {
    display: flex;
    flex-direction: column;

    #dataset-upload-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      #dataset-search {
        flex: 1;
        min-height: 43px;
        filter: none;
        border: 1px solid $primaryColor;
        font-weight: normal;
        font-size: 14px;
      }
    }
  }

  #datasets-right {
    flex: 1;
  }

  #dataset-table-section {
    width: 100%;
  }
}

#raw-data {
  width: 100%;
  height: 65vh;
  padding: 0.5rem;
  white-space: break-spaces;
  overflow-y: auto;
  background-color: white;
  filter: $inputShadow;
  margin-top: 0;
}
