#bookmarked-table {
  .table-body {
    overflow-y: auto;
    height: 65vh;
  }

  .table-entry {
    background-color: white;
    justify-content: flex-start;

    &:nth-child(2n) {
      background-color: $backgroundDark;
    }
  }

  .metric {
    padding: 0 0.5rem;
    min-width: 90px;
    text-align: left;
  }

  .expression {
    min-width: 180px;
  }

  span {
    margin-right: 1rem;
  }

  .table-top {
    & > span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      & > span {
        margin-right: 0.5rem;
      }
    }
  }
}
