#sessions-table {
  .table-body {
    overflow-y: auto;
    height: 100%;
  }
}

#sessions-top {
  display: flex;
  justify-content: space-between;

  #session-search {
    filter: none;
    border: 1px solid $primaryColor;
    width: 330px;
    font-weight: normal;
    font-size: 14px;
  }
}
