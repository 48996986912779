button {
  height: 43px;
  text-align: center;
  filter: $buttonShadow;
  border-radius: 3px;
  transition: 0.3s;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    cursor: pointer;
  }
}

#login-button {
  background-color: $accentColor;
  color: $lightTextColor;
  width: 100%;

  &:hover {
    background-color: $accentDark;
  }
}

#project-create-button,
#update-expression-button,
#upload-dataset-button,
#session-create-button,
#session-edit-button,
#session-run-button,
#session-stop-button,
#apply-filters-button,
.cancel-button,
.submit-button,
.save-button {
  background-color: $primaryColor;
  color: $lightTextColor;
  font-weight: 500;
  font-size: 16px;
  padding: 0 2rem;

  &:hover {
    background-color: $primaryDark;
  }

  &:disabled {
    background-color: $primaryLight;
  }
}

#session-edit-button,
#session-stop-button,
#session-run-button {
  flex: 1
}

#session-run-button {
  background-color: $accentColor;

  &:hover {
    background-color: $accentDark;
  }

  &:disabled {
    background-color: $accentLight;
  }
}

#session-stop-button {
  background-color: $dangerColor;

  &:hover {
    background-color: $dangerDark;
  }

  &:disabled {
    background-color: $dangerLight;
  }
}

#apply-filters-button {
  width: 100%;
  justify-content: center;
}

.icon-button {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
}

.cancel-button {
  background-color: $dangerLight;

  &:hover {
    background-color: $dangerDark;
  }
}

.submit-button {
  background-color: $primaryColor;

  &:hover {
    background-color: $primaryDark;
  }
}

.save-button {
  background-color: $accentColor;
  color: $primaryDark;

  svg {
    fill: $primaryDark;
    color: $primaryDark;
  }

  &:hover {
    background-color: darken($color: $accentColor, $amount: 6);
  }
}

#expand-button-container {
  position: absolute;
  left: -25px;
  top: 0;
  height: 100%;
  width: 50px;
  background: none;
  z-index: 4;

  &:hover {
    #expand-button {
      opacity: 1;
    }
  }

  #expand-button {
    opacity: 0;
    position: absolute;
    top: calc(50% - 14px);
    left: 12px;
    width: 28px;
    height: 28px;
    background-color: white;
    border: 1px solid $greyTextColor;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: $greyTextColor;
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transition: transform 1s;
    }

    &:hover {
      border-color: $primaryColor;

      svg {
        color: $primaryColor;
      }
    }

    &.expanded {
      left: 23px;
      border-radius: 0 5px 5px 0;

      svg {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transition-duration: 1s;
      }
    }
  }
}
