#swap-button-container {
  position: relative;

  #options-container {
    position: absolute;
    top: 20px;
    border: 1px solid $AMD4;
    border-radius: 3px;
    background: white;
  }

  .option {
    font-weight: 400;
    font-size: 14px;
    padding: 0.2rem 0.5rem;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      background-color: #e0ebfd;
    }
  }
}
