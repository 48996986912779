input {
  height: 43px;
  padding: 0 16px;
  filter: $inputShadow;
  outline: none;
  border: none;
  border-radius: 3px;
  font-family: $font;

  color: $primaryTextColor;

  &::placeholder {
    color: $greyTextColor;
  }
}
