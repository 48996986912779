#model-training-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  #top-buttons {
    display: flex;
    align-items: center;
    gap: .5rem;
    // justify-content: space-between;
  }

  .session-form {
    margin-top: 1rem;
  }

  #bottom-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-left: 1rem;
    }
  }

  #training-left {
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 2rem 1rem;
    transition: all 0.5s ease-in;

    &.minimized {
      min-width: 0;
      width: 0;
      padding: 2rem 0;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  #training-right {
    background-color: $background;
    width: 100%;
    height: 120vh;
    position: relative;
  }

  .close-button {
    display: none;
  }
}

#status {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .loader {
    display: inline-block;
    border: 3px solid $greyTextColor;
    border-top: 3px solid $loadingColor;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 2s linear infinite;
  }

  span {
    font-weight: 300;
    font-size: 16px;
    font-style: italic;
    margin-left: 10px;
  }

  &.running {
    color: $loadingColor;
  }

  &.finished {
    color: $accentColor;
  }

  &.failed {
    color: $dangerColor;
  }

  &.created {
    color: #dbca0f;
  }
}

#training-logs {
  white-space: pre;
  overflow-y: auto;
  height: 100%;
  margin: 0;
  padding: 0.5rem;
}

#generations-table {
  height: calc(100% - 50px);

  .table-body {
    overflow-y: auto;
    height: 100%;
  }

  .table-entry {
    background-color: white;

    &:nth-child(2n) {
      background-color: $backgroundDark;
    }
  }

  .generation {
    padding-left: 1rem;
  }

  .metric {
    width: 75px;
    padding: 0 0.5rem;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

#metrics-line-container {
  position: relative;
  overflow-y: auto;
}
