#configs-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

#configs-left {
  display: flex;
  flex-direction: column;
  min-width: 340px;
  margin-right: 2rem;

  #config-upload-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    #config-search {
      filter: none;
      border: 1px solid $primaryColor;
      font-weight: normal;
      font-size: 14px;
      margin-right: 0.5rem;
    }
  }

  #configs-table {
    width: 100%;
    height: calc(100dvh - 385px);
    background-color: transparent;

    .table-top {
      background-color: $AMD1;
    }
  }
}

#configs-right {
  width: 100%;

  #config-properties {
    width: 100%;
    height: calc(100dvh - 240px);
    border: 1px solid $primaryLight;
    border-radius: 5px;
    background-color: white;
    filter: $buttonShadow;
    padding: 0 1rem;
    margin-top: 0rem;
    position: relative;
  }
}

#config-populations {
  width: 100%;

  #add-population-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;

    #add-population-form {
      display: flex;

      #population-input {
        filter: none;
        border: 1px solid $primaryColor;
        font-weight: normal;
        font-size: 14px;
        margin-right: 0.5rem;
      }
    }

    #population-top-buttons {
      display: flex;

      button {
        &:last-child {
          margin-left: 1rem;
        }
      }
    }
  }

  .error-msg {
    font-weight: 400;
    font-size: 12px;
    color: $dangerColor;
    height: 18px;
  }

  #bottom-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
}

#add-expression-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#add-expression-modal-evaluation {
  background-color: $background;
  width: 100%;
  height: 80vh;
  position: relative;
  margin-top: 0.25rem;
}
