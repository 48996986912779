#projects-table,
#datasets-table,
#configs-table,
#sessions-table,
#comparison-table,
#generations-table,
#dataset-table,
#bookmarked-table,
#populations-table,
#overview-table {
  width: 100%;
  background-color: $AMD1;
  height: calc(100% - 50px);
  margin-top: 2rem;
  position: relative;

  span {
    transition: all 0.3s ease-in-out;
  }

  .name {
    flex-basis: 40%;
    padding-left: 1rem;

    &:hover {
      color: $primaryDark;
      cursor: pointer;
    }
  }

  .type,
  .best-accuracy,
  .last-modified,
  .trash,
  .status-container,
  .algorithm,
  .expression,
  .generation,
  .size,
  .accuracy,
  .command-type,
  .rms,
  .edit,
  .error {
    flex-basis: 15%;
    text-align: left;
  }

  .table-top {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: $primaryDark;
    }
  }

  .table-body {
    overflow-y: auto;
    height: 100%;

    .metric {
      white-space: nowrap;
    }
    position: relative;
  }

  .table-entry {
    display: flex;
    width: 100%;
    height: 90px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $AMD2;
    background-color: white;

    .status-container {
      span {
        font-weight: 300;
      }
    }

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .trash,
    .edit {
      text-align: center;
      height: 24px;

      svg {
        cursor: pointer;

        &:hover {
          color: $dangerLight;
        }
      }
    }
  }

  .expression {
    &:hover {
      cursor: copy;
    }
  }
}

#populations-table {
  .table-top {
    justify-content: space-between;
    padding-right: 1rem;

    #save-changes-btn {
      height: 30px;
      font-size: 10px;
      line-height: 15px;
      padding: 0 1.5rem;
    }
  }

  .table-entry {
    justify-content: space-between;
    height: auto;
    min-height: 90px;

    .name {
      flex-basis: 60%;
      &:hover {
        cursor: copy;
      }
    }
  }
}

#overview-table {
  margin-top: 0;
  .table-entry {
    justify-content: space-between;
    transition: all 0.3 ease-in-out;

    &.selected {
      color: $primaryDark;
      background-color: $accentLight;
    }
  }
}

#datasets-table,
#configs-table {
  .table-entry {
    justify-content: space-between;
    transition: all 0.3 ease-in-out;

    &.selected {
      color: $primaryDark;
      background-color: $accentLight;
    }

    .info {
      flex-basis: 70%;
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      text-overflow: ellipsis;

      .sub-info {
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
      }

      .config-desc {
        margin-top: 8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }

      &:hover {
        color: $primaryDark;
        cursor: pointer;
      }
    }
  }

  .trash,
  .download {
    flex-basis: 15%;
    justify-self: flex-end;
  }

  .download {
    text-align: center;
    height: 24px;
    color: $accentColor;

    svg {
      cursor: pointer;

      &:hover {
        color: $primaryDark;
      }
    }
  }
}

#comparison-table,
#generations-table,
#bookmarked-table {
  margin-top: 0;
  background: none;

  .trash {
    cursor: pointer;
    flex-basis: 20%;
    justify-self: flex-end;
  }

  .table-top {
    justify-content: left;
    background-color: $backgroundDark;

    .expression {
      flex-basis: 40%;
      padding-left: 1rem;
    }
  }

  transition: all 0.3 ease-in-out;

  .table-entry {
    justify-content: left;
    height: 45px;
    word-wrap: break-word;

    .expression {
      white-space: nowrap;
      overflow-x: auto;
      margin-left: 1rem;
      text-align: left;
      line-height: 48px;
      flex-basis: 40%;
      position: relative;
      overflow-y: clip;

      .copy-indicator {
        opacity: 0.7;
        position: absolute;
        right: 0;
        bottom: 5px;
        transition: all 0.3s ease-in-out;
        background-color: $primaryTextColor;
        color: white;
        font-size: 12px;
        padding: 0 0.5rem;
        cursor: pointer;
      }

      &:hover {
        opacity: 1;
      }

      p {
        margin: 0;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .copied-icon {
        color: $accentColor;
        margin-right: 0.25rem;
      }
    }
  }
}

#dataset-table {
  margin-top: 0;
  filter: $inputShadow;

  .table-top {
    border: 1px solid $AMD4;
  }

  .table-body {
    border: 1px solid $AMD4;
    border-top: none;
  }

  .table-entry {
    justify-content: flex-start;
    background-color: white;

    &:nth-child(2n) {
      background-color: $backgroundDark;
    }
  }

  .value,
  .header {
    width: 75px;
    padding: 0 0.5rem;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.expression {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.metric {
  span {
    max-width: 80px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
