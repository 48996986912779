.body {
  font: $body1;
}

.section-title {
  font: $h4;
}

.form-error {
  font-weight: 400;
  font-size: 12px;
  color: $dangerColor;
  height: 18px;
  position: absolute;
}

.form-item-label {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-weight: bold;
  font-family: $font;
}

#create-config-form {
  .form-error {
    margin-top: -0.5rem;
    display: block;
    text-align: left;
  }
}

#create-project-form,
#create-session-form {
  .input-container {
    position: relative;
  }

  .form-error {
    bottom: -1.5rem;
  }
}

#upload-dataset-form {
  #file-name {
    color: $primaryDark;
    font-weight: 600;
  }

  .form-error {
    margin-top: 0.2rem;
  }
}

#login-box {
  .input-container {
    position: relative;
  }

  .form-error {
    width: 100%;
    display: inline-block;
    position: unset;
  }
}
