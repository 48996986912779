#metrics-container {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding-bottom: 5rem;

  .metric {
    height: 50px;
    min-width: 375px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;

    &:nth-child(2n) {
      background-color: $backgroundDark;
    }
  }

  .metric-name {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: $primaryTextColor;
    width: 120px;
    margin-left: 1rem;
  }

  .metric-value {
    font-weight: 600;
    font-size: 16px;
    color: $primaryTextColor;
    width: 70px;
  }

  .metric-average,
  .metric-max,
  .metric-min {
    font-weight: 300;
    font-size: 14px;
    color: $accentDark;
    width: 100px;
    padding: 0 0.5rem;
  }

  .metric-max {
    color: $primaryColor;
  }

  .metric-min {
    color: $dangerDark;
  }

  #metric-table-header {
    height: 30px;
    .metric-name,
    .metric-value {
      font-weight: 600;
      font-size: 12px;
      color: $primaryDark;
      text-transform: uppercase;
    }

    .metric-average,
    .metric-max,
    .metric-min {
      font-weight: 600;
    }
  }
}
